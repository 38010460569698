import React, { useState } from "react"

const defaultState = {
  navLinks: null,
  setNavLinks: () => {},
  currentPage: null,
  setCurrentPage: () => {},
  showMobileNav: false,
}

const NavContext = React.createContext(defaultState)

const NavProvider = ({ children }) => {
  const [sitePages, setSitePages] = useState(null)
  const [currentPage, setCurrentPage] = useState(null)
  const [showMobileNav, setShowMobileNav] = useState(false)

  return (
    <NavContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        setShowMobileNav,
        setSitePages,
        showMobileNav,
        sitePages,
      }}
    >
      {children}
    </NavContext.Provider>
  )
}

export default NavContext
export { NavProvider }
