/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"

import { NavProvider } from "./src/helpers/context/nav"

export const wrapRootElement = ({ element }) => (
  <NavProvider>{element}</NavProvider>
)
